import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import './EndCall.css';

const EndCall = () => {
  const location = useLocation();
  const [timer, setTimer] = useState('00:00');
  console.log(location);
  useEffect(() => {
    if (location?.state?.timer) {
      let x = location?.state?.timer.split(':');
      let z = parseInt(x[1]) + (parseInt(x[0]) * 60);
      let remainingTime = 600 - z;
      let minutes = parseInt((remainingTime / 60).toFixed());
      let seconds = remainingTime % 60;
      setTimer(
        (minutes > 9 ? minutes : '0' + minutes) + ':'
        + (seconds > 9 ? seconds : '0' + seconds)
      );
    }
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };
  }, [location?.state?.timer]);
  return (
    <div className="App App-header">
      <div>
        {
          location?.state?.block_call ?
            <div>
              <div>
                <div>
                  <i className="fa fa-ban banicon"></i>
                </div>
                <div className='enddiv'>Call Blocked</div>
              </div>
              {/* {location?.state?.data?.partner_name && <img src={`https://kiot1.s3.ap-south-1.amazonaws.com/partner_logos/` + location?.state?.data?.partner_name + `.png`} className='logo' alt='Logo'></img>} */}
            </div> :
            location?.state?.user_busy ?
              <div>
                <div>
                  <div>
                    <i className="fa fa-ban banicon"></i>
                  </div>
                  <div className='enddiv'>Call Busy! Try again</div>
                </div>
                {/* {location?.state?.data?.partner_name && <img src={`https://kiot1.s3.ap-south-1.amazonaws.com/partner_logos/` + location?.state?.data?.partner_name + `.png`} className='logo' alt='Logo'></img>} */}
              </div>
              :
              <div>
                <div>
                  <div>
                    <i className="fa fa-phone banicon"></i>
                  </div>
                  <div className='enddiv'>Call Ended</div>
                  {location?.state?.no_answer && <div>
                    <div>No one Answered Call</div>
                    <div>Scan QR and Try again!</div>
                    </div>}
                  {!location?.state?.no_answer && location?.state?.callState !== 1 && <div>Duration: {timer}</div>}
                </div>
                {/* {location?.state?.data?.partner_name && <img src={`https://kiot1.s3.ap-south-1.amazonaws.com/partner_logos/` + location?.state?.data?.partner_name + `.png`} className='logo' alt='Logo'></img>} */}
              </div>
        }
      </div>
    </div>
  )
}

export default EndCall;
